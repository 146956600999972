export function validarEmails(value) {
    const padrao = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    value = value.replace(/\s*;\s*/g, ';').trim();

    const emails = value.split(';');

    for (let email of emails) {
        email = email.trim();

        if (!padrao.test(email)) {
            return false;
        }
    }
    return true;
}
